<template>
    <LiefengContent>
        <template v-slot:title>社区动员体系</template>
        <template v-slot:toolsbarRight>
            <div style="color:red;margin-right: 20px;line-height:30px">{{totalData.loginInfo}}</div>
        </template>
        <template v-slot:contentArea>
            <div
        style="
          display: flex;
          align-items: stretch;
          height: 150px;
          justify-content: space-around;
          margin: 20px 0;
        "
      >
        <Card class="cardsty" style="background: #2d8cf0">
          <div>
            <h1>区域设置</h1>
            <p>当前区域总数：{{ totalData.areaNum }}</p>
          </div>
        </Card>
        <Card class="cardsty">
          <div>
            <h1>负责人设置</h1>
            <p>当前负责人总数：{{ totalData.chargeNum }}</p>
          </div>
        </Card>
        <Card class="cardsty" style="background: #47cb89">
          <div>
            <h1>群组设置</h1>
            <p>当前群组总数：{{ totalData.groupNum }}</p>
          </div>
        </Card>
      </div>
      <Card :bordered="false" :dis-hover="true">
            <h3 slot="title">快捷入口</h3>
            <Button style="margin-right:10px">预约服务</Button>
            <Button style="margin-right:10px">议事厅服务</Button>
            <Button style="margin-right:10px">通知服务</Button>
            <Button style="margin-right:10px">数据统计</Button>
        </Card>
      <Card :bordered="false" :dis-hover="true">
            <h3 slot="title">用户数统计</h3>
            <LiefengTable
                :tableData="tableData"
                :talbeColumns="talbeColumns"
                :loading="loading"
                height="500px"
                :hidePage="true"
            ></LiefengTable>
        </Card>
        </template>
    </LiefengContent>
</template>

<script>
//@route("/statisticsmobilize")
import LiefengContent from '@/components/LiefengContent'
import LiefengTable from '@/components/LiefengTable'
   export default {
       data() {
            return {
              totalData: {},
              tableData: [],
              talbeColumns: [
                  {
                      title: "区域范围",
                      align: "center",
                      minWidth: 150,
                      key: "area"
                  },
                  {
                      title: "当前用户数",
                      align: "center",
                      minWidth: 150,
                      key: "userNum"
                  },
                  {
                      title: "本周新增",
                      align: "center",
                      minWidth: 150,
                      key: "weekAdd"
                  },
                  {
                      title: "昨日新增",
                      align: "center",
                      minWidth: 150,
                      key: "yesterdayAdd"
                  },
                  {
                      title: "本周活跃用户",
                      align: "center",
                      minWidth: 150,
                      key: "weekActive"
                  },

              ]
           }
        },
        methods: {
          //获取表格数据
          getTableData() {
            this.$get('/testjson/statistics/mobilizetabledata.json').then(res => {
              if(res.code == 200 && res.dataList) {
                this.tableData = res.dataList
              }
            })
          },
           //获取汇总
           gettotalData() {
             this.$Message.loading({
                    content: '数据加载中...',
                    duration: 0
                });
              this.$get('/testjson/statistics/mobilizetotaldata.json').then(res => {
                if(res.code == 200 && res.dataList) {
                    this.totalData = res.dataList;
                    this.getTableData()
                }else {
                  this.$Message.error({
                    background: true,
                    content: res.desc
                  })
                }
                this.$Message.destroy()
            }).catch(err => {
              this.$Message.error({
                background: true,
                content: "获取数据失败"
              })
              this.$Message.destroy()
            })
           }
        },
        created() {
          this.gettotalData();
        },
        components: {
            LiefengContent,LiefengTable
        }
      
    }
</script>
    
<style scoped lang='less'>
    .cardsty {
  min-width: 250px;
  width: 22%;
  background: #2db7f5;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.cardsty p {
  font-size: 16px;
  margin-top: 20px;
}
</style>